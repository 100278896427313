import React, { useContext, useMemo, useEffect, useState } from 'react';
import SurveyContext from 'contexts/SurveyContext';
import PaymentForm from 'components/payments/PaymentForm';
import DocumentSelector from './DocumentSelector';
import { useQuery, gql, useMutation } from "@apollo/client";
import DocumentGroup from './DocumentGroup';
import ButtonPrimaryOutline from 'components/common/Button/ButtonPrimary';
import ButtonPrimary from 'components/common/Button';
import JSZip, { filter } from 'jszip';
import FileSaver from 'file-saver';
import Loading from 'components/common/Loading';
import LoadingItem from 'components/loading/LoadingItem';
import useSurveyErrors from 'hooks/useSurveyErrors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChainBroken, faCloudArrowDown } from '@fortawesome/free-solid-svg-icons';
import Document from './Document';
import AppContext from 'contexts/AppContext';
import ConfirmNotice from 'components/notices/ConfirmNotice';

const GET_LETTERHEAD = gql`
  query GetLetterhead($id: String, $firmId: String, $type: String) {
    letterhead(id: $id, firmId: $firmId, type: $type) {
      id,
      url,
    }
  }
`;

const GET_DOCUMENTS = gql`
  query GetDocuments($surveyId: String, $firmId: String, $mortgageId: String, $pageId: String, $filters: JSONObject, $documentIds: [String], $pageless: Boolean) {
    documents(surveyId: $surveyId, firmId: $firmId, mortgageId: $mortgageId pageId: $pageId, filters: $filters, documentIds: $documentIds, pageless: $pageless) {
      id,
      name,
      category,
      firmId,
      type
    }
  }
  `;

const UNLINK_LTSA_PACKAGE = gql`
  mutation UnlinkLTSAPackage($surveyId: String!, $fileId: String!) {
    unlinkLTSAPackage(surveyId: $surveyId, fileId: $fileId ) {
      id
    }
  }
`;
  // reccent (surveyId: $surveyId),

export default function Documents() {
  const {file, fileId, surveyId, pages, updateFile, updateTotalsAndCalculations} = useContext(SurveyContext);
  const {currentUser, impersonating} = useContext(AppContext);
  const [fresh, setFresh] = useState(true);
  const [showConfirm, setShowConfirm] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [enabledDocuments, setEnabledDocuments] = useState([]);
  const [unlinkLTSAPackageMutation, { loading:unlinkLoading }] = useMutation(UNLINK_LTSA_PACKAGE);
  const [filters, setFilters] = useState({});
  const [pageId, setPageId] = useState();
  const [zip, setZip] = useState();
  const {errorMessages} = useSurveyErrors(pages, file);
  useEffect(() => {
    setPageId();
    setDocuments([]);
  }, [filters]);
  const { loading, error, data } = useQuery(GET_DOCUMENTS,{
    variables: {
      surveyId: surveyId, 
      firmId: file?.firmId, 
      pageId, 
      filters
    },
    skip: (file && surveyId) ? false : true,
    fetchPolicy:'no-cache',
    onCompleted (data) {
      setDocuments((documents) => ([...documents, ...data.documents]));
    }
  });

  const { loading: mortgageDocumentsLoading, data:mortgageDocumentsData } = useQuery(GET_DOCUMENTS,{
    variables: {
      surveyId: surveyId, 
      firmId: file?.firmId, 
      mortgageId: file?.answers?.mortgage?.product?.id,
      pageless: true,
    },
    skip: (file && surveyId && file?.answers?.mortgage?.product?.id) ? false : true,
    fetchPolicy:'no-cache'
  });

  const { loading: selectedDocumentsLodaing, data:selectedDocumentsData, refetch } = useQuery(GET_DOCUMENTS,{
    variables: {
      firmId: file?.firmId, 
      documentIds: file?.enabledDocuments || [],
      pageless: true,
    },
    skip: (file && surveyId) ? false : true,
    fetchPolicy:'no-cache',
  });

  const loadMore = () => {
    if (!loading) {
      if (data.documents.length > 19) {
        setPageId(data.documents.at(-1).name);
      }
    }
  }

  const { data:letterheadData } = useQuery(GET_LETTERHEAD,{
      variables: {id: file && file.letterheadId, firmId: file && file.firmId},
      skip: file && file.letterheadId ? false : true,
      fetchPolicy:'no-cache',
  });
  const { data:footerData } = useQuery(GET_LETTERHEAD,{
    variables: {id: file && file.footerId, firmId: file && file.firmId, type: "footer"},
    skip: file && file.footerId ? false : true,
    fetchPolicy:'no-cache',
  });
  const { data:sidebarData } = useQuery(GET_LETTERHEAD,{
    variables: {id: file && file.sidebarId, firmId: file && file.firmId, type: "sidebar"},
    skip: file && file.sidebarId ? false : true,
    fetchPolicy:'no-cache',
  });

  useEffect(() => {
    if (file && fresh) {
      setFresh(false);
      updateTotalsAndCalculations();
    }
  }, [file, fresh, updateTotalsAndCalculations]);


  let sortedDocuments = useMemo(() => {
    return (selectedDocumentsData?.documents || []).sort(document =>{
      let compare = document.category ? document.category.localeCompare(document.category) : 0;
      if (!compare) {
        compare = document.name.localeCompare(document.name)
      }
      return compare;
    });
  },[selectedDocumentsData]);

  const downloadAll = () => {
    const newZip = new JSZip();
    setZip(newZip);
    setTimeout(() => {
      newZip.generateAsync({ type: 'blob' }).then(function (content) {
        FileSaver.saveAs(content, `${file.fileNumber}-Documents.zip`);
        setZip();
      });
    }, 1000);
  }

  const unlinkLtsaDocuments = () => {
    unlinkLTSAPackageMutation({variables: {fileId: file.id, surveyId: file.surveyId}})
    .then((res) => {
      window.location.reload();
    });
  }

  return (
    <>
      {showConfirm && <ConfirmNotice
        title={`Are you sure?`}
        message={`This will remove the link between this file and any LTSA Package.`}
        onConfirm={unlinkLtsaDocuments}
        onClose={() => setShowConfirm(false)}
      /> }
      <div>
        <div className="flex flex-col gap-4">
          {documents && 
            <DocumentSelector 
              loadMore={loadMore} 
              filters={filters} 
              setFilters={setFilters} 
              documentsLoading={loading} 
              documents={documents}
              mortgageDocumentsLoading={mortgageDocumentsLoading} 
              mortgageDocuments={mortgageDocumentsData?.documents}
              selectedDocumentsLoading={selectedDocumentsLodaing}
              selectedDocuments={selectedDocumentsData?.documents}
            />
          }
          <div>
            <div className="flex justify-between items-center">
              <h2 className="dark:text-bone">Your Documents</h2>
              <div className="flex gap-2">
                {( impersonating || ["admin", "super-admin"].includes(currentUser?.type) ) &&
                  <ButtonPrimary disabled={unlinkLoading} onClick={() => setShowConfirm(true)} className="text-xs mb-1 border">{unlinkLoading ? <Loading/> : <><FontAwesomeIcon icon={faChainBroken}/>Unlink LTSA Documents</>}</ButtonPrimary>
                }
                {file && file.status === 'paid' && sortedDocuments.length > 0 &&
                  <ButtonPrimary disabled={zip} onClick={downloadAll} className="text-xs mb-1 bg-primary text-white border-primary dark:text-midnight">{zip ? <Loading/> : <><FontAwesomeIcon icon={faCloudArrowDown}/>Download All</>}</ButtonPrimary>
                }
              </div>
            </div>
            <hr className="mt-1 dark:border-bone"></hr>
          </div>
          <div className="relative">
            {file && file.status !== 'paid' ?
              <>
                <div className="py-4 min-h-200 flex flex-col gap-2">
                  {sortedDocuments.map((document, i) => (
                      <Document
                        zip={zip} 
                        letterhead={letterheadData?.letterhead} 
                        sidebar={sidebarData?.letterhead} 
                        footer={footerData?.letterhead}  
                        key={i} 
                        documentId={document.id} 
                        // extraData={extraData}
                        errorMessages={errorMessages}
                      />
                    ))
                  }
                </div>
                <div 
                  className="absolute top-0 bottom-0 left-0 right-0 bg-transparent-white dark:bg-transparent-grey z-40"
                  style={{
                    backdropFilter: "blur(2px)"
                  }}
                />
                <div
                  className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-start z-40"
                >
                  <div className="sticky top-10 mt-20 flex items-center justify-center z-40">
                    <div className="overflow-y-scroll max-h-payment scroller border dark:border-slate">
                      <PaymentForm returnUrl={`/files/${surveyId}/${fileId}/documents`}/>
                    </div>
                  </div>
                </div>
              </>
              :
              <div className="pb-4 flex flex-col gap-4">
                {
                  (!file || selectedDocumentsLodaing) ?
                    <>
                      <LoadingItem/>
                      <LoadingItem/>
                      <LoadingItem/>
                      <LoadingItem/>
                    </>
                  :
                  sortedDocuments.map((document, i) => (
                    <Document 
                      zip={zip} 
                      letterhead={letterheadData?.letterhead} 
                      sidebar={sidebarData?.letterhead} 
                      footer={footerData?.letterhead}  
                      key={i} 
                      documentId={document.id} 
                      // extraData={extraData}
                      errorMessages={errorMessages}
                    />
                  ))
                }
              </div>
            }
          </div>
        </div>
      </div>
    </>
  )
}
