import ButtonPrimary from 'components/common/Button';
import React, {useMemo, useContext, useRef, useState, useEffect } from 'react';
import createDocument, {createBlob, validInputs, getBase64FromUrl, base64ToHex, validLTSAInputs, variableValues, validPDFInputs, getImageDimensions} from 'helpers/document';
import { useQuery, useMutation, gql } from "@apollo/client";
import SurveyContext from 'contexts/SurveyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faTimes, faCheck, faTriangleExclamation, faPlus } from '@fortawesome/free-solid-svg-icons';
import VerifyLTSA from 'components/notices/VerifyLTSA';
import _ from "lodash";
import useRequest from 'hooks/useRequest';
import Loading from 'components/common/Loading';
import ConfirmNotice from 'components/notices/ConfirmNotice';
import LTSADocumentContent from './LTSADocumentContent';
import DocumentContent from './DocumentContent';
import ParentContext from 'contexts/ParentContext';
import PDFDocumentContent from './PDFDocumentContent';
import { PDFDocument } from 'pdf-lib';
import { getCalculatedValue } from "selectors/formSelectors";


const GET_DOCUMENT = gql`
  query GetDocument($id: String!) {
    document(id: $id) {
      id,
      name,
      template,
      type,
      info
    }
  }
`;

const GET_LTSA_DOCUMENT = gql`
  query GetLtsaDocument($fileId: String!, $documentType: String!) {
    ltsaDocument(fileId: $fileId, documentType: $documentType) {
      ltsaDocumentId,
      ltsaPackageId,
      lotrDocumentId,
      lotrFilingId
      type,
      location,
    }
  }
`;

const CREATE_LTSA_DOCUMENT = gql`
  mutation CreateLTSADocument($fileId: String!, $surveyId: String!, $documentType: String, $index: Int) {
    createLTSADocument(fileId: $fileId, surveyId: $surveyId, documentType: $documentType, index: $index) {
      ltsaDocumentId,
      ltsaPackageId,
      type,
      location,
    }
  }
`;

const REGENERATE_LTSA_DOCUMENT = gql`
  mutation RegenerateLTSADocument($fileId: String!, $surveyId: String!, $documentType: String, $index: Int) {
    regenerateLTSADocument(fileId: $fileId, surveyId: $surveyId, documentType: $documentType, index: $index) {
      ltsaDocumentId,
      ltsaPackageId,
      type,
      location,
    }
  }
`;

const REQUEST = gql`
  mutation FileRequest($fileId: String!, $surveyId: String!, $request: String!, $requestVariables: JSONObject) {
    fileRequest(fileId: $fileId, surveyId: $surveyId, request: $request, requestVariables: $requestVariables) {
      variables,
      response
    }
  }
`;

export default function Document({documentId, letterhead, footer, sidebar, zip, extraData, errorMessages}) {
  const {file, updateFile, pages, loading, setLoading} = useContext(SurveyContext);
  const downloadRef = useRef(null);
  const [showConfirmRegenerate, setShowConfirmRegenerate] = useState();
  const [showVerifyLTSA, setShowVerifyLTSA] = useState();
  const [ltsaDocuments, setLtsaDocuments] = useState([{}]);
  const [requestError, setRequestError] = useState();
  const [createLTSADocumentMutation, { loading:createLTSADocumentLoading }] = useMutation(CREATE_LTSA_DOCUMENT);
  const [regenerateLTSADocumentMutation, { loading:regenerateLTSADocumentLoading }] = useMutation(REGENERATE_LTSA_DOCUMENT);

  const { loading:getDocumentLoading, error, data:documentData } = useQuery(GET_DOCUMENT, {
    variables: {id: documentId},
    skip: documentId ? false : true
  });
  const { loading:ltsaDocLoading, error:ltsaDocError, data:ltsaDocData, refetch:refreshLTSADoc } = useQuery(GET_LTSA_DOCUMENT, {
    variables: {fileId: file && file.id, documentType: documentData && documentData.document.info && documentData.document.info.documentType},
    skip: (documentData && documentData.document.info && documentData.document.info.documentType) ? false : true,
    onCompleted: (ltsaDocData) => {
      if (ltsaDocData && ltsaDocData.ltsaDocument && ltsaDocData.ltsaDocument.length > 0) {
        setLtsaDocuments(ltsaDocData.ltsaDocument);
      }
    }
  });
  const [requestMutation, { loading:requestLoading, reset }] = useRequest({query: REQUEST});

  // add loading to button while document is loading
  // use file.answers for 
  const errors = useMemo(() => {
    if (file && documentData) {
      let errors;
      if (["ltsa", "mortgage", "pdf"].includes(documentData.document.type)) {
        errors = validLTSAInputs(file, documentData.document.info.variables);
      } else {
        errors =  validInputs({...file, answers:{...file?.answers, lawFirmLetterhead: true, footer: true, sidebar: true, ...extraData}}, documentData.document.template);
      }
      return errors ? (errorMessages ? errors.map(error => errorMessages[error] || error) : errors) : false;
    }
  }, [documentData, file, extraData]);

  const generateBlob = async () => {
    let data = {...file?.answers, ...extraData};
    if (letterhead) {
      let res = await getBase64FromUrl(letterhead.url);
      let hex = base64ToHex(res.base64);
      let dimensions = getImageDimensions(res.base64);
      let height = Math.round(12240 * (dimensions.h/dimensions.w));
      let letterheadRTF = `{{\\shp{\\*\\shpinst\\shpleft0\\shptop-2830\\shpright12240\\shpbottom${height-2830}\\shpfhdr0\\shpbxmargin\\shpbxignore\\shpbymargin\\shpbyignore\\shpwr2\\shpwrk0\\shpfblwtxt0\\shpz0\\shplid1032
        {\\sp{\\sn shapeType}{\\sv 75}}{\\sp{\\sn fFlipH}{\\sv 0}}{\\sp{\\sn fFlipV}{\\sv 0}}{\\sp{\\sn pib}{\\sv {\\pict\\${res.type}\\picw${12240}\\pich${height}\\picwgoal${12240}\\pichgoal${height}\\hex ${hex}}
        }}}}}`;
      data.lawFirmLetterhead = letterheadRTF;
    }
    if (footer) {
      let res = await getBase64FromUrl(footer.url);
      let hex = base64ToHex(res.base64);
      let dimensions = getImageDimensions(res.base64);
      let height = Math.round(12240 * (dimensions.h/dimensions.w));
      let footerRTF = `}\\titlepg
      {\\footerf 
        {{\\shp{\\*\\shpinst\\shpleft0\\shptop${14115 - height}\\shpright12240\\shpbottom14115\\shpfhdr0\\shpbxmargin\\shpbxignore\\shpbymargin\\shpbyignore\\shpwr2\\shpwrk0\\shpfblwtxt0\\shpz0\\shplid1032
        {\\sp{\\sn shapeType}{\\sv 75}}{\\sp{\\sn fFlipH}{\\sv 0}}{\\sp{\\sn fFlipV}{\\sv 0}}{\\sp{\\sn pib}{\\sv {\\pict\\${res.type}\\picw${12240}\\pich${height}\\picwgoal${12240}\\pichgoal${height}\\hex ${hex}}
        }}}}}
      }{`
      data.footer = footerRTF;
    }
    if (sidebar) {
      let res = await getBase64FromUrl(sidebar.url);
      let hex = base64ToHex(res.base64);
      let dimensions = getImageDimensions(res.base64);
      let width = Math.round(16945 * (dimensions.w/dimensions.h));
      let sidebarRTF = `{{\\shp{\\*\\shpinst\\shpleft-2830\\shptop-2830\\shpright${width - 2830}\\shpbottom14115\\shpfhdr0\\shpbxmargin\\shpbxignore\\shpbymargin\\shpbyignore\\shpwr2\\shpwrk0\\shpfblwtxt0\\shpz0\\shplid1032
        {\\sp{\\sn shapeType}{\\sv 75}}{\\sp{\\sn fFlipH}{\\sv 0}}{\\sp{\\sn fFlipV}{\\sv 0}}{\\sp{\\sn pib}{\\sv {\\pict\\${res.type}
        \\picw${width}\\pich${16945}\\picwgoal${width}\\pichgoal${16945}\\hex ${hex}}
        }}}}}`;
      data.sidebar = sidebarRTF;
    }
    const blob = createBlob({...file, answers: data}, documentData.document.template);
    return blob;
  }
  
  const generateDocument = async () => {
    const blob = await generateBlob();
    const url = createDocument(blob);
    downloadRef.current.setAttribute('href', url);
    downloadRef.current.setAttribute('download', `${file?.fileNumber ? `${file.fileNumber}-` : ''}${_.camelCase(documentData.document.name)}.rtf`);
    downloadRef.current.click();
  }

  const generatePDFdocument = async () => {
    const blob = await generatePDFblob();
    const url = createDocument(blob);
    downloadRef.current.setAttribute('href', url);
    downloadRef.current.setAttribute('download', _.camelCase(documentData.document.name)+".pdf");
    downloadRef.current.click();
  }

  const generatePDFblob = async () => {
    const formUrl = documentData?.document?.info?.pdfLink;
    const formPdfBytes = await fetch(formUrl).then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(formPdfBytes, { ignoreEncryption: true });
    const form = pdfDoc.getForm();
    // fill fields
    const fields = form.getFields()
    fields.forEach(field => {
      if (field.setText) {
        const name = field.getName();
        let value = getCalculatedValue(file, name, false, false, true);
        field.setText(value?.toString() || "");
      }
    })
    const pdfBytes = await pdfDoc.save();
    return new Blob([pdfBytes], {type: "application/pdf"});
  }

  useEffect(async () => {
    if (zip && documentData.document && documentData.document.type !== "ltsa") {
      let blob;
      if (["ltsa", "mortgage", "pdf"].includes(documentData.document.type)) {
        blob = await generatePDFblob();
        zip.file( _.camelCase(documentData.document.name)+".pdf", blob);
      } else {
        blob = generateBlob();  
        zip.file( _.camelCase(documentData.document.name)+".rtf", blob);
      }
    
    }
  }, [zip, documentData]);

  let requestNeeded = useMemo(() => {
    if (errors && documentData?.document?.info?.requests?.length > 0) {
      for (let error of errors) {
        for (let request of documentData.document.info.requests) {
          if (error.includes('requests.' + request.request)) {
            return request;
          }
        }
      }
    }
    return false;
  }, [documentData, errors]);

  const performRequest = (override) => {
    if (requestNeeded) {
      if (override || (file.tokens["LTSAtoken"] && (new Date(Date.now()) < new Date(file.tokens["LTSAtoken"])))) {
        setLoading(true);
        requestMutation({variables: {fileId: file.id, surveyId: file.surveyId, request: requestNeeded.request, requestVariables: requestNeeded.variables}})
          .then(res => {
            console.log("res", res);
            setLoading(false);
            updateFile({requests: {...file.requests, [requestNeeded.request]: res.data.fileRequest}});
          })
          .catch(res => {
            // TODO
            // check if error is due to token expiry
            console.log("err", res);
            reset();
            console.log(res.errors)
            setLoading(false);
            setRequestError(res.message.replace("Error:", ""));
          })
      } else {
        setShowVerifyLTSA("request");
      }
    }
  }

  const handleRegenerateLTSADocument = () => {
    if (documentData.document.type === "ltsa") {
      if (file.tokens["LTSAtoken"] && (new Date(Date.now()) < new Date(file.tokens["LTSAtoken"]))) {
        regenerateLTSADocument(showConfirmRegenerate.index);
      } else {
        setShowVerifyLTSA(true);
      }
    }
  }

  const handleCreateLTSADocument = (index) => {
    if (documentData.document.type === "ltsa") {
      if (file.tokens["LTSAtoken"] && (new Date(Date.now()) < new Date(file.tokens["LTSAtoken"]))) {
        createLTSADocument(index);
      } else {
        setShowVerifyLTSA({index});
      }
    }
  }
  const createLTSADocument = (index) => {
    setLoading(true);
    createLTSADocumentMutation({variables: {
      fileId: file.id, surveyId: file.surveyId, documentType: documentData.document.info.documentType, index: index || showVerifyLTSA?.index || 0
    }})
    .then((res) => {
      setShowVerifyLTSA(false);
      refreshLTSADoc();
      setLoading(false);
    })
    .catch((res) => {
      setLoading(false);
    });
  }

  const regenerateLTSADocument = (index) => {
    setLoading(true);
    setShowConfirmRegenerate(false);
    regenerateLTSADocumentMutation({variables: {
      fileId: file.id, surveyId: file.surveyId, documentType: documentData.document.info.documentType, index: index || 0
    }})
    .then((res) => {
      setShowVerifyLTSA(false);
      refreshLTSADoc();
      setLoading(false);
    })
    .catch((res) => {
      setLoading(false);
    });
  }

  const loadingStatus = regenerateLTSADocumentLoading || createLTSADocumentLoading || ltsaDocLoading || loading;

  return (
    <>
      {showVerifyLTSA && <VerifyLTSA onClose={() => setShowVerifyLTSA(false)} onConfirm={() => showConfirmRegenerate ? regenerateLTSADocument() : (showVerifyLTSA == "request" ? performRequest(true) : createLTSADocument())}/>}
      {showConfirmRegenerate && <ConfirmNotice
        title={`Are you sure?`}
        message={`This will remove the existing LTSA document and regenerate a new one.`}
        onConfirm={handleRegenerateLTSADocument}
        onClose={() => setShowConfirmRegenerate(false)}
      /> }
      <div className={`flex flex-col border rounded border-l-6 bg-white dark:bg-shadow dark:border-midnight shadow-md hover:shadow-lg ${errors ? "border-red" : (documentData?.document.type === "ltsa" ? "border-green" : documentData?.document.type === "mortgage" ? "border-orange" : "border-grey")}`}>
        { !documentData && !file && <div className="p-6 m-auto"><Loading/></div>}
        { documentData && 
            (documentData.document.type === "ltsa" ? 
              <>
                {ltsaDocuments.map((ltsaDocument, i) => 
                  <ParentContext.Provider key={i} value={{row: i, parentPath: `documentData.${documentData.document.info.documentType}_${i}`, prevRows: [i]}}>
                    <LTSADocumentContent
                      errors={errors}
                      document={documentData.document}
                      ltsaDocument={ltsaDocument}
                      loading={loadingStatus}
                      setShowConfirmRegenerate={() => setShowConfirmRegenerate({index: i})}
                      handleCreateLTSADocument={() => handleCreateLTSADocument(i)}
                    />
                  </ParentContext.Provider>
                )}
                {documentData.document.info.multiple && <ButtonPrimary onClick={() => setLtsaDocuments([...ltsaDocuments, {}])}>Add <FontAwesomeIcon icon={faPlus}/></ButtonPrimary>}
              </>
            : ["mortgage", "pdf"].includes(documentData.document.type) ? 
              <PDFDocumentContent
                errors={errors}
                document={documentData.document}
                loading={loadingStatus}
                generateDocument={generatePDFdocument}
                downloadRef={downloadRef}
              />
            
            :
              <DocumentContent
                document={documentData.document}
                requestNeeded={requestNeeded}
                requestError={requestError}
                loading={requestLoading}
                performRequest={performRequest}
                generateDocument={generateDocument}
                downloadRef={downloadRef}
                errors={errors}
              />
            )
        }
      </div>
    </>
  )
}
