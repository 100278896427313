import React, {useContext, useMemo} from 'react';
import CalendarContext from 'contexts/CalendarContext';
import { sameDay } from 'helpers/calendar';

const letterDayLookup = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', "Saturday"];

export default function WeekDay({dayOfWeek, currentDate}) {
  const {setDate, date, monthEvents, colorMode} = useContext(CalendarContext);

  const todaysEvents = useMemo(() => monthEvents.filter(event => sameDay(new Date(event.date + "T00:00"), currentDate)), [monthEvents, currentDate]);

  return (
    <div 
      className={`
        w-1/7 min-w-8 relative cursor-pointer overflow-hidden
        group
        ${date.getDate() === currentDate.getDate() ? 'rounded-sm z-20 scale-125 bg-light-light-grey dark:bg-midnight text-black border border-b-2 border-transparent-dark-grey dark:text-bone dark:border-black dark:border-b-primary border-b-primary font-md shadow-lg' : "border dark:border-black bg-white hover:bg-light-light-grey dark:bg-shadow hover:scale-110 hover:font-md hover:z-10"}
        hover:border-med-grey hover:border-b-primary hover:border-b-2
      `}
      // TODO ifs this so it uses date obj instead of calendar date
      onClick={() => setDate(new Date(currentDate))}
    >
      <div className="mt-2x-full"/>
        <div className="flex flex-col pt-1 items-center gap-1 absolute top-0 left-0 right-0 bottom-0 px-0.5">
          <small className={`${date.getDate() === currentDate.getDate() ? 'font-medium': ''}`}>{letterDayLookup[dayOfWeek].slice(0,3)}</small>
          <div className={`
            divide-y w-full
            flex flex-col gap-px
            overflow-y-scroll
            scroller
            ${date.getDate() !== currentDate.getDate() && 'opacity-50'}
          `}>
              {todaysEvents.map((event,i) => 
                <div 
                  key={i+(colorMode ? event.user.color : event.colorOne)} 
                  className={`shrink-0 flex bg-white rounded text-white overflow-hidden ${!event.colorTwo && event.percentage == 1 ? 'opacity-30' : ''}`}
                  style={{border: `1px solid ${event.colorOne}`, backgroundImage: `linear-gradient(to right, ${colorMode ? event.user?.color : event.colorOne} ${(Number(event.percentage) || Number(event.percentage) === 0) ? 100 - event.percentage*100 : '100'}%, ${colorMode ? event.user.color : (event.colorTwo || event.colorOne)})`}}
                >
                  <span className="text-xxs">
                    {event.icon}
                  </span>
                  <div className="flex overflow-hidden">
                    <span className="text-xxs font-medium whitespace-nowrap group-hover:animate-ticker">{event.name}</span>
                  </div>
                </div>
              )}
          </div>
        </div>
    </div>
  )
}
