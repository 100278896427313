import React, { useContext, useState } from 'react';
import TrustAccountTable from 'components/trustAccount/TrustAccountTable';
import { List, Radios } from 'components/inputs';
import useTrustAccountChoices from 'hooks/useTrustAccountChoices';
import useFirmChoices from 'hooks/useFirmChoices';
import ButtonPrimary from 'components/common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import ActivePageContext from 'contexts/ActivePageContext';

export default function All() {
  const {setActivePage, firmId, from, to, filters, trustAccountId, trustAccount, setExportCSV} = useContext(ActivePageContext);
  setActivePage("")
  return (
    <TrustAccountTable
      firmId={firmId}
      from={from}
      to={to}
      trustAccount={trustAccount}
      filters={filters}
      trustAccountId={trustAccountId}
      setExportCSV={setExportCSV}
      documents={["Receipts Report", "General Ledger", "Journal"]}
      add
    />
  )
}
