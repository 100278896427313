import React, {useContext, useEffect, useState, useMemo} from 'react';
import {getCalculatedValue, getSurveyValue, pathMaker} from 'selectors/formSelectors';
import SurveyContext from 'contexts/SurveyContext';
import Tooltip from 'components/common/Tooltip';
import Input from 'components/inputs/Input';
import ButtonPrimary from 'components/common/Button';
import { useMutation, gql, useQuery } from "@apollo/client";
import ltsaLogo from "assets/images/survey/ltsalogo.svg";
import ParentContext from 'contexts/ParentContext';
import { List } from 'components/inputs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-regular-svg-icons';
import { faCircleMinus, faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import Loading from 'components/common/Loading';
import ConfirmNotice from 'components/notices/ConfirmNotice';

const GET_ANSWER_TEMPLATES = gql`
  query GetAnswerTemplates($firmId: String, $surveyId: String) {
    surveyAnswerTemplates(firmId: $firmId, surveyId: $surveyId) {
      id,
      name,
      firmId
    }
  }
`;

const GET_ANSWER_TEMPLATE = gql`
  query GetAnswerTemplate($id: String) {
    surveyAnswerTemplate(id: $id) {
      id,
      name,
      firmId,
      template
    }
  }
`;

const ADD_ANSWER_TEMPLATE = gql`
  mutation AddAnswerTemplate($firmId: String, $name: String!, $surveyId: String!, $template: JSONObject!) {
    newSurveyAnswerTemplate(firmId: $firmId, name: $name, surveyId: $surveyId, template: $template) {
      id
    }
  }
`;

const UPDATE_ANSWER_TEMPLATE = gql`
  mutation updateSurveyAnswerTemplate($id: String!, $template: JSONObject!) {
    updateSurveyAnswerTemplate(id: $id, template: $template) {
      id
    }
  }
`;

const REMOVE_ANSWER_TEMPLATE = gql`
  mutation removeSurveyAnswerTemplate($id: String!) {
    removeSurveyAnswerTemplate(id: $id) {
      id
    }
  }
`;

const AnswerTemplateInput = ({ 
  paths, 
  showSave,
  description,
  label,
  questionKey,
  defaults
}) => {
  const { file, updateFile, updateAnswers } = useContext(SurveyContext);
  const {row, parentPath} = useContext(ParentContext);
  const [name, setName] = useState("");
  const [selectedValue, setSelectedValue] = useState();
  const [showSaveUi, setShowSaveUi] = useState();
  const [showNotice, setShowNotice] = useState(false);
  const [showDeleteNotice, setShowDeleteNotice] = useState(false);
  const [showUpdateNotice, setShowUpdateNotice] = useState(false);
  const [loadTemplateAnswers, setLoadTemplateAnswers] = useState(false);
  const [queuedUpdate, setQueuedUpdate] = useState();
  const [addAnswerTemplateMutation, { loading:addTemplateLoad }] = useMutation(ADD_ANSWER_TEMPLATE);
  const [updateAnswerTemplateMutation, { loading:updateTemplateLoad }] = useMutation(UPDATE_ANSWER_TEMPLATE);
  const [removeAnswerTemplateMutation, { loading:removeTemplateLoad }] = useMutation(REMOVE_ANSWER_TEMPLATE);


  const path = useMemo(() => {
      return pathMaker(questionKey, parentPath, row);
  }, [questionKey, parentPath, row]);

  const surveyValue = useMemo(() => {
    let value = file && getSurveyValue(file.answers, path);
    return value;
  }, [file, path]);

  useEffect(() => {
    if (queuedUpdate) {
      updateAnswers([...queuedUpdate.keys, path], [...queuedUpdate.values, selectedValue], true);
      setQueuedUpdate();
    }
  }, [queuedUpdate]);

  const { data:templateData } = useQuery(GET_ANSWER_TEMPLATE, {
    variables: { id: (selectedValue || surveyValue) }, 
    skip: !(selectedValue || surveyValue) || !loadTemplateAnswers,
    fetchPolicy:'no-cache',
    onCompleted(data) {
      setLoadTemplateAnswers(false);
      setShowNotice(false);
      // first wipe categorizer
      console.log("test", data);
      let template = {...defaults, ...data.surveyAnswerTemplate.template}
      let keys = Object.keys(template);
      let values = Object.values(template);
      updateAnswers(keys, [], false);
      setQueuedUpdate({keys, values});
    }
  });

  const { loading, error, data, refetch } = useQuery(GET_ANSWER_TEMPLATES, {
    variables: { firmId: file && file.firmId, surveyId: file.surveyId },
    skip: file ? false: true,
    fetchPolicy:'no-cache'
  });

  const saveTemplateAnswers = () => {
    let template = {};
    for (let path of paths) {
      template[path] = getSurveyValue(file.answers, path)
    }
    addAnswerTemplateMutation({variables: {firmId: file && file.firmId, surveyId: file.surveyId, name, template: {...template, ...(defaults || {})}}})
    .then((res) => {
      refetch();
      setSelectedValue(res.data.newSurveyAnswerTemplate.id);
      setShowSaveUi(false);
    })
    .catch(err => {
      refetch();
      setShowSaveUi(false);
    })
  }

  const updateTemplateAnswers = () => {
    setShowUpdateNotice(false)
    let template = {};
    for (let path of paths) {
      template[path] = getSurveyValue(file.answers, path)
    }
    updateAnswerTemplateMutation({variables: {id: selectedValue || surveyValue, template: {...template, ...(defaults || {})}}})
    .then((res) => {
      refetch();
      setShowSaveUi(false);
    })
    .catch(err => {
      console.log("err:", err);
      refetch();
      setShowSaveUi(false);
    })
  }

  const deleteTemplate = () => {
    removeAnswerTemplateMutation({variables: {id: selectedValue }})
    .then((res) => {
      refetch();
      setShowSaveUi(false);
    })
    .catch(err => {
      console.log("err:", err);
      refetch();
      setShowSaveUi(false);
    })
  }

  let options = useMemo(() => {
    let options = [];
    if (data && data.surveyAnswerTemplates) {
      options = data.surveyAnswerTemplates.map(template => ({name: template.name, value: template.id}))
    }
    options.push({value: '', name: 'none'});
    return options;
  }, [data]);

  let selectedName = useMemo(() => {
    return options.find(option => option.value === surveyValue)?.name
  }, [surveyValue, options]);
  return (
    <>
      {showNotice && <ConfirmNotice
        title={`Are you sure?`}
        message={`This will replace all ${label} values with values from the saved template.`}
        onConfirm={() => setLoadTemplateAnswers(true)}
        onClose={() => setShowNotice(false)}
      /> }
      {showDeleteNotice && <ConfirmNotice
        title={`Are you sure?`}
        message={(
          <>
            <p className="leading-5">This will permanently delete this saved template.</p>
            <p>Type "{selectedName || "DELETE"}" to delete the template.</p>
          </>
        )}
        confirmText={selectedName || "DELETE"}
        onConfirm={deleteTemplate}
        onClose={() => setShowDeleteNotice(false)}
      /> }
      {showUpdateNotice && <ConfirmNotice
        title={`Warning!`}
        message={(
          <>
            <p className="leading-5 dark:text-bone">This will overwrite the template, changing the saved template answers for all users. To load the template answers to this file click the load button instead.</p>
            <p className='dark:text-bone'>Type "{selectedName || "UPDATE"}" to re-write the template.</p>
          </>
        )}
        onConfirm={updateTemplateAnswers}
        confirmText={selectedName || "UPDATE"}
        onClose={() => setShowUpdateNotice(false)}
      /> }
      <div className="w-full">
        <div className={`rounded border dark:border-slate p-4 flex flex-col gap-2 bg-light-light-grey dark:bg-shadow ${showSaveUi ? 'border-green' : "border-grey"} w-full`}>
          <h2 className="dark:text-bone"><FontAwesomeIcon icon={faFile}/> {label} Templates</h2>
          <p className="dark:text-bone">{description}</p>
          <div className="flex flex-col w-full">
            <label className="dark:text-bone">Select Template</label>
            <div className="flex justify-between w-full">
              <div className="flex gap-2 items-center">
                <List options={options} valueOverride={selectedValue || surveyValue} onChange={(val) => setSelectedValue(val)} />
                {showSave && <FontAwesomeIcon icon={faCirclePlus} className="text-green cursor-pointer" onClick={() => setShowSaveUi(!showSaveUi)}/>}
              </div>
              {
                (surveyValue || selectedValue) &&
                <div className="flex gap-2 items-center">
                  <ButtonPrimary className="self-end" disabled={updateTemplateLoad || !(surveyValue || selectedValue)} onClick={() => setShowUpdateNotice(true)}>{(updateTemplateLoad || !(surveyValue || selectedValue)) ? <Loading/> : "Update"}</ButtonPrimary>
                  <ButtonPrimary className="self-end text-green border border-green" disabled={loadTemplateAnswers || !(surveyValue || selectedValue)} onClick={() => setShowNotice(true)}>{(loadTemplateAnswers || !(surveyValue || selectedValue)) ? <Loading/> : "Load"}</ButtonPrimary>
                  {(surveyValue || selectedValue) && <FontAwesomeIcon icon={faCircleMinus} className="text-red cursor-pointer" onClick={() => setShowDeleteNotice(true)}/>}
                </div>
              }
            </div>
          </div>
        </div>
          {
            showSave && showSaveUi &&
            <div className="rounded-b -mt-1 border p-4 flex flex-col gap-2 bg-white border-green border-t-light-grey w-full dark:border-t-green dark:bg-dark-coffee">
              <h2 className="dark:text-bone">Save current answers as a Template?</h2>
              <p className="text-sm dark:text-bone">Your current {label} answers will be saved and loadable in future surveys.</p>
              <div className="flex justify-between w-full">
                <div className="flex flex-col">
                  <label className='dark:text-bone'>Name</label>
                  <Input valueOverride={name} onChange={setName} />
                </div>
                <ButtonPrimary className="self-end" disabled={!name || addTemplateLoad} onClick={saveTemplateAnswers}>{addTemplateLoad ? <Loading/> : "Save"}</ButtonPrimary>
              </div>
            </div>
          }
      </div>
    </>
  )
};

export default AnswerTemplateInput;