import React from 'react';
import { Link } from 'react-router-dom';
import Badge from 'components/common/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

export default function MiniFileItem({file, search}) {
  let type = () => {
    switch(file.status) {
      case 'paid':
        return 'success';
      case 'pending':
        return 'warning';
      case 'error':
        return 'error';
      default:
        return 'warning';
    }
  }
  let searchComponents = () => {
    let startIndex = file.searchMatch?.toLowerCase().search(search?.toLowerCase());
    return <span>{file.searchMatch.substr(0, startIndex)} <span className="bg-yellow">{search}</span>{file.searchMatch.substr(startIndex + search?.length)}</span>
  }
  
  return (
    <Link className="group no-underline flex flex-col hover:bg-primary rounded" to={`/files/${file.surveyId}/${file.id}/summary`}>
      <div key={file.id} className="flex group-hover:border-grey rounded md:min-w-60 p-1 gap-1">
        <div className="flex items-center justify-center"><span>{file.icon || '📄'}</span></div>
        <div className="flex gap-8 justify-between w-full items-center">
          <div className="flex gap-1 items-center">
            <h3 className="font-medium text-white">{file.nickname && `${file.nickname}`}</h3>
            <small className="text-primary text-xs group-hover:text-dark-grey">#{file.fileNumber || file.id}</small>
          </div>
          <div className="flex gap-2 items-center">
            {
              file.searchMatch &&
              <div className="flex gap-1 px-1 py-1 items-center border-med-grey rounded border">
                <FontAwesomeIcon className="text-med-grey text-xs" icon={faSearch}/>
                <p className="px-1 text-xs text-med-grey font-semibold">{searchComponents()}</p>
              </div>
            }
            {file.completionDate &&
              <>
                <div className="font-semibold text-xs text-med-grey cursor-pointer border-med-grey rounded border px-3 py-1">{new Date (file.completionDate + 'T00:00').toLocaleString('default', {month: 'numeric', day: "numeric"} )}</div>
              </>
            }
            {file.status == "paid" &&
              <FontAwesomeIcon className="text-green md:hidden" icon={faCheckCircle} />
            }
          </div>
        </div>
      </div>
    </Link>
  )
}
