import React from 'react';
import { Link } from 'react-router-dom';
import Badge from 'components/common/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

export default function FileItem({file, search}) {
  let type = () => {
    switch(file.status) {
      case 'paid':
        return 'success';
      case 'pending':
        return 'warning';
      case 'error':
        return 'error';
      default:
        return 'warning';
    }
  }
  let searchComponents = () => {
    let startIndex = file.searchMatch?.toLowerCase().search(search.toLowerCase());
    return <span>{file.searchMatch.substr(0, startIndex)} <span className="bg-yellow">{search}</span>{file.searchMatch.substr(startIndex + search.length)}</span>
  }
  
  return (
    <Link className="group no-underline flex flex-col rounded shadow-sm bg-white dark:bg-shadow" to={`/files/${file.surveyId}/${file.id}/summary`}>
      <div key={file.id} className="flex border-l-4 border border-light-grey group-hover:border-grey dark:border-midnight dark:hover:border-black rounded md:min-w-60">
        <div className="bg-light-grey flex items-center justify-center pr-1 group-hover:bg-grey dark:bg-midnight dark:group-hover:bg-black"><span>{file.icon || '📄'}</span></div>
        <div className="flex p-2 gap-8 justify-between w-full items-center">
          <div>
            <small className="text-primary">#{file.fileNumber || file.id}</small>
            <h3 className="-mt-1 font-medium dark:text-bone">{file.nickname && `${file.nickname}`}</h3>
          </div>
          <div className="flex gap-2 items-center">
            {
              file.searchMatch &&
              <div className="flex gap-1 px-1 py-1 items-center border-med-grey rounded border-2">
                <FontAwesomeIcon className="text-med-grey" icon={faSearch}/>
                <p className="px-1 text-xs text-med-grey font-semibold">{searchComponents()}</p>
              </div>
            }
            {file.completionDate &&
              <>
                <div className="font-semibold text-xs text-med-grey cursor-pointer border-med-grey rounded border-2 px-3 py-1 hidden md:block">{file.completionDate}</div>
                <div className="font-semibold text-xs text-med-grey cursor-pointer border-med-grey rounded border-2 px-3 py-1 md:hidden">{new Date (file.completionDate + 'T00:00').toLocaleString('default', {month: 'numeric', day: "numeric"} )}</div>
              </>
            }
            <div className="hidden md:block">
              <Badge text={file.status == "paid" ? "processed" : "pending"} type={type()}/>
            </div>
            {file.status == "paid" &&
              <FontAwesomeIcon className="text-green md:hidden" icon={faCheckCircle} />
            }
          </div>
        </div>
      </div>
    </Link>
  )
}
