import React, {useState, useRef, useEffect, useContext, useMemo} from 'react';
import Input from 'components/inputs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faBuilding, faLink } from '@fortawesome/free-solid-svg-icons';
import AddBusiness from './AddBusiness';
import { useQuery, useMutation, gql } from "@apollo/client";
import Business from 'components/business/Business';
import SurveyContext from 'contexts/SurveyContext';
import Tooltip from 'components/common/Tooltip';
import {getCalculatedValue, getSurveyValue, pathMaker} from 'selectors/formSelectors';
import { typeToLabel } from 'helpers/general';
import ButtonPrimary from 'components/common/Button';
import ParentContext from 'contexts/ParentContext';
import stringSimilarity from "string-similarity";

const GET_BUSINISSES = gql`
  query GetBusinesses($filters: JSONObject, $type: [String], $firmId: String) {
    businesses(filters: $filters, type: $type, firmId: $firmId) {
      id,
      companyName,
      email,
      phoneNumber,
      fax,
      addressLineOne,
      addressLineTwo,
      city,
      province,
      gstNumber,
      pstNumber,
      incorporationNumber,
      postalCode,
      type,
      public,
      corporateDirectors,
      directors,
      shareholders,
      fundsTransferAuthorizedSubmitter,
      fundsTransferAccountOwner,
      fundsTransferAccountNamePtt,
      linkedFirm {
        id
      }
    }
  }
`;

export default function BusinessInput({hideLabel, label, calculatedLabel, description, type, questionKey, multiple, questions, onSelect, valueOverride, removable, publicBusiness, mini, specialFields}) {
  const [search, setSearch] = useState();
  const { file, updateAnswers, updateErrors } = useContext(SurveyContext);
  const searchRef = useRef();
  const { row, prevRows, parentPath } = useContext(ParentContext);
  const { loading, error, data, refetch } = useQuery(GET_BUSINISSES, {
    variables: {filters: {strictSearch: true, search}, firmId: file && file.firmId, type},
    fetchPolicy: 'no-cache',
    skip: !search || search === ' ' ? true : false
  });
  const [selected, setSelected] = useState([]);
  const [showNew, setShowNew] = useState();
  const path = useMemo(() => {
    return pathMaker(questionKey, parentPath, row);
  }, [questionKey, parentPath, row]);
  const calculatedLabelValue = useMemo(() => {
    return calculatedLabel && file && file.answers && getCalculatedValue(file, calculatedLabel, prevRows);
  }, [calculatedLabel]);

  const handleShowNew = (val) => {
    setShowNew(val);
    if (val) {
      setSearch('');
    } else {
      setSearch(' ');
    }
  }

  const handleSelect = (val, refreshSearch = false) => {
    if (multiple) {
      let newValue = [];
      let replace = false;
      for (let value of selected) {
        if (value.id === val.id) {
          newValue.push(val);
          replace = true;
        } else {
          newValue.push(value);
        }
      }
      if (!replace) newValue.push(val);
      setSelected(newValue);
      if (onSelect) {
        onSelect(newValue);
      } else {
        updateAnswers && updateAnswers(path, newValue)
      }
    } else {
      setSelected([val]);
      if (onSelect) {
        onSelect(val);
      } else {
        updateAnswers && updateAnswers(path, val)
      }
    }
    setShowNew(false);
    setSearch('');
    if (refreshSearch) refetch();
  }

  const handleRemove = (i) => {
    let newValue = null;
    if (multiple) {
      newValue = [...selected];
      newValue.splice(i, 1);
    } else {
      newValue = [];
    }
    if (onSelect) {
      setSelected(newValue)
      onSelect(multiple ? newValue : newValue[0]);
    } else {
      updateAnswers && updateAnswers(path, newValue);
    }
  }

  const surveyValue = useMemo(() => {
    return file && getSurveyValue(file.answers, path);
  }, [file, path]);

  useEffect(() => {
    let value = valueOverride ? (multiple ? valueOverride.map(business => ({...business})) : {...valueOverride}) : surveyValue;
    setSelected(multiple ? value || [] : ((value && Object.keys(value).length > 0) ? [value] : []));
  }, [surveyValue, multiple, valueOverride]);

  useEffect(() => {
    if (!showNew && search === " ") {
      searchRef.current.focus();
    }
  }, [search, showNew])

  const options = data ? 
    [...data.businesses].sort((businessA, businessB) => {
      let comapareA = stringSimilarity.compareTwoStrings(businessA.companyName, search);
      let compareB = stringSimilarity.compareTwoStrings(businessB.companyName, search);
      if (comapareA > compareB) {
        return -1;
      }
      return 1;
    }).map(business => (
      <div 
        key={business.id}
        className="hover:bg-light-light-grey dark:bg-shadow rounded cursor-pointer p-2 flex gap-6 justify-between items-center relative"
        onClick={() => handleSelect(business)}
      >
        <div>
          {business.companyName.trim()}
          {business.linkedFirm && <FontAwesomeIcon  className={`absolute pl-1 top-2 text-sm text-green`} icon={faLink}/>}
        </div>
        <small className="text-xxs">&nbsp;{business.addressLineOne}</small>
      </div>
    )) 
    : [];

  return (
    <div className="flex flex-col justify-start gap-2 items-start">
       {!hideLabel && (label || calculatedLabelValue) && (
          description ? 
              <label className='dark:text-bone'>
                  {label || calculatedLabelValue}
                  <Tooltip message={description} />
              </label>
          :
              <label className='dark:text-bone'>
                  {label || calculatedLabelValue}
              </label>
      )}
      {
        showNew &&
        <AddBusiness publicBusiness={publicBusiness} firmId={file ? file.firmId : null} type={type} close={() => handleShowNew(false)} onCreate={handleSelect} specialFields={specialFields}/>
      }
      {
        !showNew && (selected.length < 1 || multiple) &&
        <div className={`flex flex-col ${showNew && 'hidden'}`}>
          <div className="relative">
            <Input placeholder={`Find ${typeToLabel(type) || "Business"}`} valueOverride={search} forwardRef={searchRef} className={`pl-7 ${search && 'rounded-b-none'}`} onChange={setSearch}/>
            <FontAwesomeIcon  className={`absolute top-2.5 left-2 text-sm text-med-grey`} icon={faMagnifyingGlass}/>
          </div>
          {search &&
            <div className="flex flex-col border border-t-none p-4 gap-2 bg-white dark:bg-midnight dark:border-slate shadow-md rounded-b">
              <div onClick={() => handleShowNew(true)}className="border border-green self-start text-green rounded py-1 px-4 cursor-pointer">New {typeToLabel(type) || "Business"}!</div>
              <hr className="dark:border-bone"></hr>
              <div className={`flex flex-col max-h-32 ${options.length > 1 ? 'scroller overflow-y-scroll' : ''}`}>
                {
                  options.length > 0 ?
                  options
                  :
                  <small className="text-light-grey">None Found</small>
                }
              </div>
            </div>
          }
        </div>
      }
      <div className="flex gap-2 flex-wrap">
        {
          selected?.map((business, i) => 
            <ParentContext.Provider key={i} value={{row: multiple ? i : null, parentPath: path, prevRows: multiple ? [...prevRows, i] : prevRows}}>
              <Business specialFields={specialFields} firmId={file ? file.firmId : null} type={type} onUpdate={handleSelect} key={i} business={business} onDeselect={(removable || !valueOverride) && (() => handleRemove(i))} valueOverride={valueOverride} mini={mini}>
                {questions}
              </Business>
            </ParentContext.Provider>
          )
        }
      </div>
    </div>
  )
}
