import React, { useState, useEffect, useContext } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripeCardSetupForm from './StripeCardSetupForm';
import { useMutation, gql } from "@apollo/client";
import { useHistory } from 'react-router';
import AppContext from 'contexts/AppContext';
import SurveyContext from 'contexts/SurveyContext';
import { useNavigate } from 'react-router-dom';

const INITIALIZE = gql`
  mutation GetPaymentIntent($fileId: String!, $surveyId: String!) {
    getPaymentIntent(fileId: $fileId, surveyId: $surveyId) {
      paymentIntent {
        id,
        amount,
        status,
        client_secret,
        metadata,
        fileCredits
      },
      paymentMethods
    }
  }
`;

const CONFIRMPAYMENT = gql`
  mutation ConfirmPaymentIntent($fileId: String!, $surveyId: String!, $paymentMethodId: String, $code: String) {
    confirmPaymentIntent(fileId: $fileId, surveyId: $surveyId, paymentMethodId: $paymentMethodId, code: $code) {
      status
    }
  }
`;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function PaymentForm() {
  const {setLoading, loading} = useContext(AppContext);
  const {fileId, file, surveyId, updateFile} = useContext(SurveyContext);
  const [paymentIntent, setPaymentIntent] = useState();
  const [paymentMethods, setPaymentMethods] = useState();
  const [code, setCode] = useState();
  const navigate = useNavigate();
  const [initializeMutation, { loading:initializeLoading }] = useMutation(INITIALIZE);
  const [confirmPaymentMutation, { loading:confirmPaymentLoading, error:setPaymentError }] = useMutation(CONFIRMPAYMENT);
  if (confirmPaymentLoading && !loading) {
    // setLoading(true);
  } else if (!confirmPaymentLoading && loading) {
    // setLoading(false);
  }
  
  useEffect(() => {
    initializeMutation({ variables: { fileId: fileId, surveyId: surveyId } })
      .then(({data}) => {
          if (data.getPaymentIntent.paymentIntent.metadata.code) {
            setCode(data.getPaymentIntent.paymentIntent.metadata.code);
          }
          setPaymentIntent(data.getPaymentIntent.paymentIntent);
          if (data.getPaymentIntent.paymentMethods && Array.isArray(data.getPaymentIntent.paymentMethods) && data.getPaymentIntent.paymentMethods.length > 0) {
            setPaymentMethods(data.getPaymentIntent.paymentMethods);
          }
      })
      .catch((error) => {
        console.log("error for getting secret", error.message);
        if (error.message === "Already paid") {
          updateFile({status: "paid"});
        } else {
          // TODO HANDLE REAL ERROR MESSAGE
        }
      })
  }, [fileId]);
  
  const handleSuccess = (paymentMethodId) => {
    confirmPaymentMutation({ variables: {  fileId: fileId, surveyId: surveyId, paymentMethodId, code } })
      .then(() => {
        updateFile({status: "paid"});
        // TODO SHOW PAYMENT SUCCESS MESSAGE
      })
      .catch((err) => {
        console.log("payment error", err);
      })
  };

  return (
    <div className="bg-white rounded p-4 border-t dark:bg-midnight dark:border-slate">
      {paymentIntent && <Elements stripe={stripePromise} options={{clientSecret: paymentIntent.client_secret}}>
        <StripeCardSetupForm 
          submitLoading={confirmPaymentLoading} 
          file={file}
          code={code}
          setCode={setCode}
          paymentMethods={paymentMethods} 
          setPaymentMethods={setPaymentMethods} 
          paymentIntent={paymentIntent} 
          handleSuccess={handleSuccess}
        />
      </Elements>}
    </div>
  );
}

export default PaymentForm;

