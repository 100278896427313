import React, {useContext, useMemo} from 'react';
import CalendarContext from 'contexts/CalendarContext';
import { sameDay } from 'helpers/calendar';

export default function MonthDay({dayOfWeek, dayOfMonth, empty}) {
  const {setDate, date, monthEvents, colorMode} = useContext(CalendarContext);

  const todaysEvents = useMemo(() => {
    return monthEvents.filter(event => dayOfMonth === new Date(event.date+"T00:00").getDate());
  }, [monthEvents, dayOfMonth]);

  return (
    <div className={`
      flex relative w-1/7
      ${empty ? 'bg-white dark:bg-transparent' : 'border bg-white cursor-pointer hover:bg-light-light-grey dark:bg-shadow hover:font-md hover:z-10 hover:border-black dark:border-black hover:shadow-lg'}
      ${(!empty && date.getDate() === dayOfMonth) ? 
        'rounded-sm z-20 bg-light-light-grey dark:bg-midnight font-md scale-125 border-b-2 border-med-grey hover:border-b-primary border-b-primary shadow-lg text-black dark:text-bone dark:border-black dark:border-b-primary' 
        : 
        `shadow-md ${!empty && 'hover:scale-110'}`
      }
      `}
      onClick={() => !empty && setDate(new Date((new Date(date)).setDate(dayOfMonth)))}
    >
      {
        !empty &&
        <>
          <div className="mt-full"/>
          <div className={`
            flex flex-col absolute justify-between top-0 left-0 right-0 bottom-0 p-0.5 overflow-hidden
            ${(!empty && date.getDate() === dayOfMonth) ? '' : 'opacity-75'}
            `
          }>
            <small className={`${(!empty && date.getDate() === dayOfMonth) ? 'font-semibold' : 'font-medium '} `}>{dayOfMonth}</small>
            <div className="flex flex-col overflow-y-scroll scroller-min h-full justify-end content-end">
              {todaysEvents?.map((event, i) => 
              <div
                key={i+(colorMode ? event.user?.color : event.colorOne)} 
                className={`pl-0.5 rounded h-2.5 flex ${!event.colorTwo && event.percentage == 1 ? 'opacity-30' : ''}`}
                style={{border: `1px solid ${event.colorOne}`, backgroundImage: `linear-gradient(to right, ${colorMode ? event.user?.color : event.colorOne} ${(Number(event.percentage) || Number(event.percentage) === 0) ? 100 - event.percentage*100 : '100'}%, ${colorMode ? event.user.color : (event.colorTwo || event.colorOne)})`}}
              >
                <span className="text-xxs -mt-0.23">{event.icon} </span>
                <span className="text-xxxs text-white">#{event.tag}</span>
              </div>
              )}
            </div>
          </div>
        </>
      }
    </div>
  )
}
