import React, {useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

const options = [
    '📌',
    '⚡',
    '🔷',
    '♦',
    '💥',
    '🔰',
    '🟢',
    '🎏',
    '☕',
    '🎉',
    '💡',
    '🎁',
    '🎄',
    '🎀',
    '👓',
    '💎',
    '⚽',
    '🎲',
    '🎓',
    '🎵',
    '🩺',
    '🚧',
    '🏗',
    '🏠',
    '🏡',
    '🏦',
    '💰',
    '🌃',
    '🧱',
    '📑',
    '📂',
    '📤',
    '📥',
    '👨‍👩‍👦',
    '👨‍👩‍👧', 
    '👨‍👨‍👦',
    '👨‍👨‍👧',
    '👨‍👨‍👧‍👦',
    '👩‍👩‍👦',
    '👩‍👩‍👧‍👦',
    '👨‍👧',
    '👨‍👧‍👦',
    '👩‍👦',
    '👩‍👦‍👦',
];

export default function IconSelector({initialValue, onChange, small}) {
  const [value, setValue] = useState(initialValue || '');
  const [showIcons, setShowIcons] = useState();

  const changeValue = (newValue) => {
    setValue(newValue);
    onChange(newValue);
  }
  return (
    <div className="relative">
        <div className="flex flex-col items-start cursor-pointer" onClick={() => setShowIcons(!showIcons)}>
          {value ?
            <h1 className={`${small ? 'text-base' : ''}`}>{value} <FontAwesomeIcon className="relative top-1 text-base" icon={faCaretDown} /></h1>
            :
            <h1 className={`bg-light-grey dark:bg-midnight rounded ${small ? 'text-base' : ''}`}><span className="opacity-0">❌</span><FontAwesomeIcon className="relative top-1 text-base" icon={faCaretDown} /></h1>
          }
        </div>
        {
          showIcons && 
          <div className={`p-1 text-2xl flex flex-wrap gap-3 ${small ? 'absolute w-fullscreen border bg-white rounded z-10 w-160' : ''}`}>
            {options.map((option) => (
                  <div
                    className="hover:text-3xl hover:bg-light-grey dark:hover:bg-shadow hover:p-1 p-2 cursor-pointer"
                    key={option}
                    name={option} 
                    value={option}
                    onClick={() => {
                      setShowIcons(false);
                      changeValue(option);
                    }}
                  >
                    {option}
                  </div>
              ))
            }
          </div>
        }
    </div>
  )
}
