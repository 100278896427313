import React, {useContext, useEffect, useMemo, useState} from 'react';
import {getCalculatedValue, getSurveyValue, pathMaker} from 'selectors/formSelectors';
import SurveyContext from 'contexts/SurveyContext';
import Tooltip from 'components/common/Tooltip';
import Checkboxes from 'components/inputs/Checkboxes';
import ParentContext from 'contexts/ParentContext';
import { softSubset } from 'helpers/general';
import _ from 'lodash';

const SurveyTickbox = ({ 
    type, 
    questionKey, 
    label, 
    options,
    hideLabel, 
    total, 
    query,
    required,
    collapsible,
    isReadOnly,
    defaultValue,
    inputSymbol,
    selected,
    formStyles, // TODO change to input style
    description
}) => {
    const { file, updateAnswers, updateErrors } = useContext(SurveyContext);
    const { row, parentPath, prevRows}= useContext(ParentContext);
    const [cache, setCache] = useState();
    const path = useMemo(() => {
        return pathMaker(questionKey, parentPath, row);
    }, [questionKey, parentPath, row]);
    const [error, setError ] = useState();

    const surveyValue = useMemo(() => {
        let value = file && getSurveyValue(file.answers, path);
        if (!value && defaultValue && value !== 0) {
            updateValue(defaultValue);
        }
        return value;
    }, [file, path]);

    const calculatedOptions = useMemo(() => {
        let value = options && file && file.answers && getCalculatedValue(file, options, prevRows);
        value = (Number(value) ? Math.round(value * 100)/100 : value);
        if (_.isEqual(cache, value)) {
            return cache;
        }
        setCache(value);
        return [...value];
    }, [options, file, prevRows]);

    
    useEffect(() => {
        if (calculatedOptions?.[0] && selected && (!surveyValue && surveyValue !== null)) {
            updateValue(calculatedOptions[0].value)
        }
    }, [calculatedOptions, surveyValue]);

    const checkError = (val) => {
        if (required && !val) {
            setError("Field Required!");
            updateErrors(path, true);
            return true;
        }
        updateErrors(path, false);
        return false;
    }
    const updateValue = (val) => {
        if (!checkError(val)) {
            updateAnswers(path, val ? val : null);
        }
    }
    return (
        <div>
            {!hideLabel && label && (
                description ? 
                    <label className='dark:text-bone'>
                        {label}
                        <Tooltip message={description} />
                    </label>
                :
                    <label className='dark:text-bone'>
                        {label}
                    </label>
            )}
            <Checkboxes className={formStyles} singleValue={true} readOnly={isReadOnly} options={calculatedOptions} valueOverride={surveyValue ? [surveyValue] : []} onChange={(val) => updateValue(val)} />
            <p>{error}</p>
        </div>
    )
};

export default SurveyTickbox;