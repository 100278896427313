import React, { useEffect } from 'react';
import 'styles/App.css';
import RequestFlagsProvider from 'providers/RequestFlagsProvider';
import ApolloClientProvider from 'providers/ApolloClientProvider';
import AppProvider from 'providers/AppProvider';
import NavBar from 'components/NavBar';
import Footer from 'components/footer';
import ScrollToTop from 'components/common/ScrollToTop';
import Notices from 'components/notices';
// routes
import Screens from 'screens';
import BugProvider from 'providers/BugProvider';
import { Route, Routes } from 'react-router-dom';
import TopNav from 'components/NavBar/TopNav';
import SideNav from 'components/NavBar/SideNav';

const App = () => {
  useEffect(() => {
    const darkMode = localStorage.getItem('dark-mode');
    document.getElementById('root').className = darkMode ? 'dark' : '';
  }, []);
  return (
    <BugProvider>
      <RequestFlagsProvider>
        <ApolloClientProvider>
          <AppProvider>
            <div className="h-screen flex flex-col justify-start overflow-hidden bg-transparent-grey dark:bg-shadow dark:text-bone dark:[color-scheme:dark]">
              <Routes>
                  <Route key="landing" path="/landing" element={<Footer />} />
                  <Route key="index" path="/" element={<NavBar />} />
                  <Route key="about" path="/about" element={<NavBar />} />
                  <Route key="terms" path="/terms-and-conditions" element={<NavBar />} />
                  <Route key="privacy" path="/privacy" element={<NavBar />} />
                  <Route
                    path="*"
                    element={<TopNav/>}
                  />
              </Routes>
              <div className="flex flex-row flex-grow flex-shrink min-h-0">
                <SideNav/>
                <div className="justify-start h-full w-full overflow-auto bg-light-light-yellow dark:bg-black rounded">
                  <Notices/>
                  <div className="flex flex-col justify-start min-h-full ">
                    <Screens/>
                    <Routes>
                        <Route key="landing" path="/landing" element={<Footer />} />
                        <Route key="index" path="/" element={<Footer />} />
                        <Route key="about" path="/about" element={<Footer />} />
                        <Route key="terms" path="/terms-and-conditions" element={<Footer />} />
                        <Route key="privacy" path="/privacy" element={<Footer />} />
                        <Route
                          path="*"
                          element={<></>}
                        />
                    </Routes>
                  </div>
                </div>
              </div>
            </div>
            <ScrollToTop/>
          </AppProvider>
        </ApolloClientProvider>
      </RequestFlagsProvider>
    </BugProvider>
  );
};

export default App;
