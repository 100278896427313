import React, {useContext, useEffect, useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FullWidthPage, PaddedPage } from 'components/common/Layout';
import ButtonPrimary from 'components/common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleChevronRight, faDownLong, faDownload, faFileSignature, faHouse, faMinusCircle, faMoneyBill, faPerson, faRightLong, faTimes, faUser, faUserTie } from '@fortawesome/free-solid-svg-icons';
import SurveyContext from 'contexts/SurveyContext';
import LetterheadSelect from 'components/letterheads/LetterheadSelect';
import { faBuilding, faCircleRight, faNoteSticky, faUser as faUserOutline } from '@fortawesome/free-regular-svg-icons';
import ButtonPrimaryOutline from 'components/common/Button/ButtonPrimary';
import ConfirmNotice from 'components/notices/ConfirmNotice';
import { gql, useMutation, useQuery } from '@apollo/client';
import DuplicateFile from './DuplicateFile';
import AppContext from 'contexts/AppContext';
import AssignFile from './AssignFile';
import LoadingText from 'components/loading/LoadingText';
import LoadingIcon from 'components/loading/LoadingIcon';

const DELETE_FILE = gql`
  mutation DeleteFile($surveyId: String!, $fileId: String!) {
    deleteFile(surveyId: $surveyId, fileId: $fileId) {
      id
    }
  }
`;

const GET_CHECKLIST = gql`
  query GetChecklistAnswer($fileId: String, $surveyId: String) {
    checklistAnswer(fileId: $fileId, surveyId: $surveyId) {
      id,
      notes,
    }
  }
`;

export default function FileSummary() {
  const {currentUser} = useContext(AppContext);
  const {setSurveyNav, fileId, surveyId, file, loading} = useContext(SurveyContext);
  const [showConfirm, setShowConfirm] = useState();
  const [deleteFileMutation, { loading:deleteLoading }] = useMutation(DELETE_FILE);
  const admin = ["admin", "super-admin"].includes(currentUser?.type);
  const { data: checklistData } = useQuery(GET_CHECKLIST,{
    variables: {fileId, surveyId},
    skip: !fileId,
  });

  const navigate = useNavigate();

  useEffect(() => {setSurveyNav('summary')}, [setSurveyNav]);

  const deleteFile = () => {
    deleteFileMutation({variables: {surveyId: file.surveyId, fileId: file.id}})
    .then((res) => {
      navigate("/files");
    });
  }
  
  return (
    <PaddedPage>
      {showConfirm && 
        <ConfirmNotice    
          title="Remove File?"
          message={
            <div>
              Are you sure you want to delete this file?
            </div>
          }
          onClose={() => setShowConfirm(false)}
          onConfirm={deleteFile}
        />
      }
      <div className="flex flex-col gap-4">
        {/* <h1>File Summary</h1> */}
        {
          (!file || loading) ?
          <div className="flex rounded bg-light-light-grey dark:bg-shadow flex flex-col border justify-start gap-4 p-4 max-w-8xl dark:border-transparent-grey">
            <div className="flex justify-between">
              <LoadingText color="primary" random/>
            </div>
            <div className="flex gap-1 items-center pl-2">
              <LoadingIcon/>
              <div className="flex flex-col">
                <LoadingText random/>
              </div>
            </div>
            <div className="flex flex-col border bg-white dark:bg-dark-coffee rounded dark:border-transparent-grey">
              <div className="flex justify-between items-center">
                <div className="flex gap-1 items-center p-2">
                  <LoadingIcon/>
                  <div className="flex flex-col">
                    <LoadingText random/>
                  </div>
                </div>
                <div className="flex gap-2 px-4">
                  <LoadingIcon/>
                  <div className="flex flex-col">
                    <LoadingText random/>
                  </div>
                </div>
              </div>
              <hr className="border-2 border-light-light-grey dark:border-transparent-grey"></hr>
              <div className="flex items-stretch gap-4 p-2 py-4">
                <div className="flex flex-col justify-center rounded p-3">
                  <div className="flex gap-1">
                    <LoadingIcon/>
                    <div className="flex flex-col">
                      <LoadingText random/>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-center">
                  <FontAwesomeIcon className="text-xl leading-3 text-light-light-grey dark:text-bone" icon={faCircleRight}/>
                </div>
                <div className="flex flex-col justify-center rounded p-3">
                  <div className="flex gap-1">
                    <LoadingIcon/>
                    <div className="flex flex-col">
                      <LoadingText random/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-2 flex-wrap">
                <div className="flex gap-1 items-center pl-2 rounded border dark:border-transparent-grey bg-white dark:bg-midnight p-2 pr-3">
                  <div className="flex gap-1">
                    <LoadingIcon/>
                    <div className="flex flex-col">
                      <LoadingText random/>
                    </div>
                  </div>
                </div>
                <div className="flex gap-1 items-center pl-2 rounded border dark:border-transparent-grey bg-white dark:bg-midnight p-2 pr-3">
                  <div className="flex gap-1">
                    <LoadingIcon/>
                    <div className="flex flex-col">
                      <LoadingText random/>
                    </div>
                  </div>
                </div>
                <div className="flex gap-1 items-center pl-2 rounded border dark:border-transparent-grey bg-white dark:bg-midnight p-2 pr-3">
                  <div className="flex gap-1">
                    <LoadingIcon/>
                    <div className="flex flex-col">
                      <LoadingText random/>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          :
          <div className="flex rounded bg-light-light-grey dark:bg-shadow flex flex-col border dark:border-transparent-grey justify-start gap-4 p-4 max-w-8xl">
            <div className="flex justify-between">
              <h2 className="text-primary ml-1.5">{file?.survey.name}</h2>
              <div className="flex gap-2">
                { currentUser?.auth('admin', file.firmId) && 
                  <>
                    <AssignFile file={file}/>
                    <ButtonPrimaryOutline onClick={() => setShowConfirm(true)} className="text-sm"><FontAwesomeIcon icon={faMinusCircle}/>Delete File</ButtonPrimaryOutline>
                  </>
                }
                { currentUser?.auth('priviledged', file.firmId) &&
                  <>
                    <DuplicateFile file={file}/>
                  </>
                }
              </div>
            </div>
            <div className="flex gap-1 items-center pl-2">
              <div className="rounded-full w-7 h-7 flex items-center justify-center bg-background-dark text-white dark:text-bone">
                <FontAwesomeIcon className="text-sm" icon={faUserTie}/>
              </div>
              <div className="flex flex-col">
                <p className="dark:text-bone font-bold text-sm leading-4 dark:text-bone">{file?.answers.supervisingLawyer ? `${file?.answers.supervisingLawyer.firstName} ${file?.answers.supervisingLawyer.lastName}`: '-'}</p>
                <p className="dark:text-bone ml-1 text-sm leading-4 dark:text-bone">{file?.answers.conveyancer ? `${file?.answers.conveyancer.firstName} ${file?.answers.conveyancer.lastName}`: '-'}</p>
              </div>
            </div>
            <div className="flex flex-col border bg-white dark:bg-dark-coffee rounded border dark:border-black">
              <div className="flex flex-col md:flex-row justify-between md:items-center">
                <div className="flex gap-1 items-center p-2">
                  <div className="rounded-full w-7 h-7 flex items-center justify-center bg-transparent-darkest-grey text-white dark:text-bone">
                    <FontAwesomeIcon className="text-sm" icon={faHouse}/>
                  </div>
                  <div className="flex flex-col font-semibold text-med-grey">
                    <p className="dark:text-bone leading-3 font-semibold text-xs text-med-grey dark:text-bone">{file?.answers.newPurchaseAddress || '-'}</p>
                    <p className="dark:text-bone leading-3 font-semibold text-xs text-med-grey ml-1 dark:text-bone">{file?.answers.addressDetails ? `${file?.answers.addressDetails.newPurchaseCity}, ${file?.answers.addressDetails.newPurchaseProvince}` : '-'}</p>
                  </div>
                </div>
                <div className="flex gap-2 px-4">
                  {
                    file?.answers.mortgage &&
                    <div className="flex gap-1">
                      <FontAwesomeIcon className="text-transparent-darkest-grey dark:text-bone text-sm" icon={faFileSignature}/>
                      <p className="dark:text-bone text-xs leading-3 font-semibold text-med-grey">{file?.answers.mortgage?.product?.name || '-'}</p>
                    </div>
                  }
                  {
                    file?.answers.purchasePriceTotal &&
                    <div className="flex gap-1">
                      <FontAwesomeIcon className="text-transparent-darkest-grey dark:text-bone text-sm" icon={faMoneyBill}/>
                      <p className="dark:text-bone text-xs leading-3 font-semibold text-med-grey">${file?.answers.purchasePriceTotal}</p>
                    </div>
                  }
                </div>
              </div>
              <hr className="border-2 border-light-light-grey dark:border-black dark:border"></hr>
              <div className="flex flex-col md:flex-row items-stretch gap-4 p-2">
                <div className="flex flex-col justify-center rounded border border-med-grey p-3">
                  {
                    (file?.answers.allSellers || file?.answers.allTransferors || []).map((seller, i) =>
                      <div key={i} className={`flex gap-2 ${i > 0 ? `ml-${i*2}` : ''}`}>
                        <FontAwesomeIcon className="text-transparent-darkest-grey dark:text-bone text-sm" icon={faUser}/>
                        <p className="dark:text-bone font-semibold text-xs leading-3 text-med-grey">{seller.companyName || `${seller.firstName} ${seller.lastName}`}</p>
                      </div>
                    )
                  }
                  {
                    (file?.answers.allSellers || file?.answers.allTransferors || []).length === 0 &&
                    <div className={`flex gap-2`}>
                      <FontAwesomeIcon className="text-transparent-darkest-grey dark:text-bone text-sm" icon={faUser}/>
                      <p className="dark:text-bone font-semibold text-xs leading-3 text-med-grey">-</p>
                    </div>
                  }
                </div>
                <div className="hidden md:flex flex-col justify-center">
                  <FontAwesomeIcon className="text-xl leading-3 text-transparent-darkest-grey dark:text-bone" icon={faCircleRight}/>
                  {
                    file?.answers.completionDate &&
                    <p className="dark:text-bone h-2 text-xxs text-med-grey font-semibold leading-3">{file?.answers.completionDate}</p>
                  }
                </div>
                <div className="flex md:hidden flex-col justify-center">
                  <FontAwesomeIcon className="text-3xl leading-3 text-background-dark" icon={faDownLong}/>
                </div>
                <div className="flex flex-col justify-center rounded border border-med-grey p-3">
                  {
                    (file?.answers.allBuyers || file?.answers.allBorrowers || []).map((buyer, i) =>
                      <div key={i} className={`flex gap-2 ${i > 0 ? `ml-${i*2}` : ''}`}>
                        <FontAwesomeIcon className="text-transparent-darkest-grey dark:text-bone text-sm" icon={faUser}/>
                        <p className="dark:text-bone font-semibold text-xs leading-3 text-med-grey">{buyer.companyName || `${buyer.firstName} ${buyer.lastName}`}</p>
                      </div>
                    )
                  }
                  {
                    (file?.answers.allBuyers || file?.answers.allBorrowers || []).length === 0 &&
                    <div className={`flex gap-2`}>
                      <FontAwesomeIcon className="text-transparent-darkest-grey dark:text-bone text-sm" icon={faUser}/>
                      <p className="dark:text-bone font-semibold text-xs leading-3 text-med-grey">-</p>
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className="flex flex-col md:flex-row gap-2 flex-wrap">
              {file?.answers.sellersFirm &&
                <div className="flex gap-1 items-center pl-2 rounded border bg-white dark:bg-midnight dark:border-black p-2 pr-3">
                  <div className="rounded-full w-6 h-8 flex items-center justify-center ">
                    <FontAwesomeIcon className="text-med-grey" icon={faBuilding}/>
                  </div>
                  <div className="flex flex-col">
                    <p className="dark:text-bone font-semibold text-xs leading-3 text-med-grey">Seller's Firm</p>
                    <p className="dark:text-bone ml-1 text-xs leading-3 text-med-grey">{file?.answers.sellersFirm.companyName}</p>
                  </div>
                </div>
              }
              {file?.answers.listingAgent &&
                <div className="flex gap-1 items-center pl-2 rounded border bg-white dark:bg-midnight dark:border-black p-2 pr-3">
                  <div className="rounded-full w-6 h-8 flex items-center justify-center ">
                    <FontAwesomeIcon className="text-med-grey" icon={faBuilding}/>
                  </div>
                  <div className="flex flex-col">
                    <p className="dark:text-bone font-semibold text-xs leading-3 text-med-grey">Listing Agent</p>
                    <p className="dark:text-bone ml-1 text-xs leading-3 text-med-grey">{file?.answers.listingAgent.companyName}</p>
                  </div>
                </div>
              }
              {file?.answers.sellingAgent &&
                <div className="flex gap-1 items-center pl-2 rounded border bg-white dark:bg-midnight dark:border-black p-2 pr-3">
                  <div className="rounded-full w-6 h-8 flex items-center justify-center ">
                    <FontAwesomeIcon className="text-med-grey" icon={faBuilding}/>
                  </div>
                  <div className="flex flex-col">
                    <p className="dark:text-bone font-semibold text-xs leading-3 text-med-grey">Selling Agent</p>
                    <p className="dark:text-bone ml-1 text-xs leading-3 text-med-grey">{file?.answers.sellingAgent.companyName}</p>
                  </div>
                </div>
              }
              {(file?.answers.mortgageLenders || (file?.answers.lender ? [file?.answers.lender]: [])).map((lender, i) => 
                <div key={i} className="flex gap-1 items-center pl-2 rounded border bg-white dark:bg-midnight dark:border-black p-2 pr-3">
                  <div className="rounded-full w-6 h-8 flex items-center justify-center ">
                    <FontAwesomeIcon className="text-med-grey" icon={faBuilding}/>
                  </div>
                  <div className="flex flex-col">
                    <p className="dark:text-bone font-semibold text-xs leading-3 text-med-grey">Lender</p>
                    <p className="dark:text-bone ml-1 text-xs leading-3 text-med-grey">{lender.companyName}</p>
                  </div>
                </div>
              )}
              {file?.answers.insuranceBroker &&
                <div className="flex gap-1 items-center pl-2 rounded border bg-white dark:bg-midnight dark:border-black p-2 pr-3">
                  <div className="rounded-full w-6 h-8 flex items-center justify-center ">
                    <FontAwesomeIcon className="text-med-grey" icon={faBuilding}/>
                  </div>
                  <div className="flex flex-col">
                    <p className="dark:text-bone font-semibold text-xs leading-3 text-med-grey">Insurance Broker</p>
                    <p className="dark:text-bone ml-1 text-xs leading-3 text-med-grey">{file?.answers.insuranceBroker.companyName}</p>
                  </div>
                </div>
              }
            </div>
            {
              checklistData?.checklistAnswer?.notes?.main &&
              <div className="flex flex-col gap-2">
                <h3 className="text-med-grey ml-2">Notes</h3>
                <div className="flex gap-1 items-center pl-2 rounded border bg-white dark:bg-midnight dark:border-black p-2 pr-3 text-sm leading-4">
                  <div className="rounded-full w-6 h-8 flex items-center justify-center ">
                    <FontAwesomeIcon className="text-med-grey" icon={faNoteSticky}/>
                  </div>
                  <pre className='dark:text-bone'>
                    {checklistData?.checklistAnswer?.notes?.main}
                  </pre>
                </div>
              </div>
            }
          </div>
        }
        <div className="flex flex-wrap gap-4 max-w-8xl">
          <LetterheadSelect/>
          <LetterheadSelect type="footer"/>
          <LetterheadSelect type="sidebar"/>
        </div>
        {/* <br></br>
        <Link className="" to={`/files/${surveyId}/${fileId}/documents`}>
          <ButtonPrimary>
            Next <FontAwesomeIcon icon={faArrowRight} />
          </ButtonPrimary>
        </Link> */}
      </div>
    </PaddedPage>
  )
}
