import React, {useState, useContext} from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faMask, faCircleHalfStroke } from '@fortawesome/free-solid-svg-icons';
import AppContext from 'contexts/AppContext';
import { faUser } from '@fortawesome/free-regular-svg-icons';

export default function Dropdown({options, trigger}) {
  const [show, setShow] = useState(false);
  const {currentUser, impersonating} = useContext(AppContext);
  const toggleDarkMode = () => {
    localStorage.setItem('dark-mode', localStorage.getItem('dark-mode') ? '' : true);
    const darkMode = localStorage.getItem('dark-mode');
    document.getElementById('root').className = darkMode ? 'dark' : '';
  }
  return (
    <div className="">
      <div className="cursor-pointer rounded-full w-7 h-7 bg-dark-grey hover:brightness-75 text-light-grey flex justify-center items-center" onClick={() => setShow(!show)}>
        {trigger}
        {/* <FontAwesomeIcon className="bottom-0 cursor-pointer" icon={faCaretDown}/>  */}
      </div>
      <div className={`top-0 left-0 w-screen h-screen z-60 ${show ? 'absolute' : 'hidden'}`}>
        <div 
          onClick={() => setShow(!show)}
          className="absolute top-0 bottom-0 left-0 right-0"
          style={{
            backdropFilter: "brightness(80%)"
          }}
        />
          <div className={`absolute bg-light-light-grey dark:bg-shadow rounded-xl shadow divide-y flex flex-col border right-11 top-12 min-w-24 dark:border-bone dark:divide-bone`}>
              <div className="p-1 pt-2 px-4 bg-white dark:bg-midnight rounded-t-xl flex justify-between">
                <p className="whitespace-nowrap text-sm dark:text-bone"><FontAwesomeIcon icon={impersonating ? faMask: faUser}/> {currentUser.firstName} {currentUser.lastName}</p>
                <FontAwesomeIcon onClick={toggleDarkMode} className="dark:text-white text-midnight cursor-pointer" icon={faCircleHalfStroke}/>
              </div>
              <div className="flex flex-col">
                {options.map(option => (
                  <div className="p-2 px-4 hover:bg-transparent-grey border-b border-transparent-grey" key={option.text}>
                    {option.to ?
                        <Link className="no-underline font-normal hover:font-bold" to={option.to} onClick={() => setShow(false)}>
                            <div onClick={option.action}>
                                {option.text}
                            </div> 
                        </Link>
                      :
                      <div className="cursor-pointer whitespace-nowrap hover:font-bold" onClick={option.action}>
                          {option.text}
                      </div>}
                  </div>
                ))}
              </div>
          </div>
      </div>
    </div>
  )
}
