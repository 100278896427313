import React, {useContext, useMemo} from 'react';
import { getFirstDayOfMonth } from 'helpers/calendar';
import MonthDay from './MonthDay';
import CalendarContext from 'contexts/CalendarContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faArrowAltCircleRight } from '@fortawesome/free-regular-svg-icons';

export default function CalendarMonth() {
	const {date, setDate, nextMonth, prevMonth, monthEvents} = useContext(CalendarContext);

  	const firstDayOfMonth = useMemo(() => getFirstDayOfMonth(date), [date]);
	const firstDayOfMonthCalendarDay = useMemo(() => firstDayOfMonth.getDate(), [firstDayOfMonth]);
	
	const monthDays = useMemo(() => {
		let days = [];
		let currentDate = new Date(firstDayOfMonth);
		let currentMonth = firstDayOfMonth.getMonth() ;
		let x = 0;
		for (let i = 0; i < firstDayOfMonth.getDay(); i ++) {
			days.push(<MonthDay key={`${i}+empty`} empty />);
		}

		while (currentDate.getMonth() === currentMonth) {
			days.push(<MonthDay key={currentMonth + x + monthEvents?.length} dayOfWeek={x} dayOfMonth={firstDayOfMonthCalendarDay + x} />);
			currentDate.setDate(currentDate.getDate() + 1);
			x ++;
		}
		return days;
	}, [firstDayOfMonthCalendarDay, firstDayOfMonth, monthEvents])

	let monthName = new Date(date);
	monthName = monthName.toLocaleString('en-US', {month: 'long'}); // {month:'long'}

	return (
    <div className="flex flex-col gap-2 rounded">
		<div className="flex justify-between">
			<FontAwesomeIcon className={`${prevMonth ? '' : 'h-0'} cursor-pointer`} onClick={prevMonth} icon={faArrowAltCircleLeft}/>
			<p className="font-bold text-center dark:text-bone">{monthName}</p>
			<FontAwesomeIcon className={`${nextMonth ? '' : 'h-0'} cursor-pointer`} onClick={nextMonth} icon={faArrowAltCircleRight}/>
		</div>
		<div className="flex justify-around text-sm">
			<p className="font-medium dark:text-bone" >S</p>
			<p className="font-medium dark:text-bone" >M</p>
			<p className="font-medium dark:text-bone" >T</p>
			<p className="font-medium dark:text-bone" >W</p>
			<p className="font-medium dark:text-bone" >T</p>
			<p className="font-medium dark:text-bone" >F</p>
			<p className="font-medium dark:text-bone" >S</p>
		</div>
		<div className="flex flex-wrap">
			{monthDays}
		</div>
	</div>
  )
}
